<template>
    <v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title>
            <span class="headline">Notas de {{ nombre }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('closeDialog')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-col>
                <v-text-field
                    label="Crear nueva nota"
                    placeholder="Ingresar un texto"
                    v-model="textoNota"
                    hide-details="auto"
                >
                    <template v-slot:append-outer>
                        <v-icon 
                            color="success"
                            @click="storeNota"
                        >
                            mdi-floppy
                        </v-icon>
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="6"> 
                <v-select
                    :items="tiposNotas"
                    label="Filtrar notas por: "
                    v-model="filtroNotas"
                    dense
                    @change="filtarNotas"
                />
            </v-col>
            <v-card
                elevation="2" 
                v-for="(nota, index) in notasFiltradas"
                :key="index"
                color="orange lighten-5"
                class="mb-2"
                style="border-bottom-right-radius: 30px;"
            >
                <v-card-title>
                    <div 
                        v-html="nota.note_name" 
                        :style="`text-decoration-line: ${!nota.thru_date ? 'none' : 'line-through'};`"
                    ></div>
                    <v-spacer></v-spacer>
                    <div style="font-size:11px;flex-basis: 150px;" class="d-flex align-center">
                        <div>
                            <div class="text-right" style="height: 20px;">{{nota.user_login_id}} </div>
                            <div style="height: 20px;"> {{ nota.note_date }}</div>
                        </div>
                        <div>
                            <v-btn 
                                icon
                                @click="updateNota(nota)"
                            >
                                <v-icon :color="`${nota.thru_date ? 'success' : 'error'}`">
                                    {{ nota.thru_date ? 'mdi-check' : 'mdi-delete-forever' }}
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-text 
                    v-html="nota.note_info" 
                    :style="`text-decoration-line: ${!nota.thru_date ? 'none' : 'line-through'}`" 
                />
            </v-card>
        </v-card-text>
        <v-card-actions> 
            <v-col cols="12" class="text-center">
                <v-btn 
                    color="default"
                    @click="$emit('closeDialog')"
                >
                    Cerrar
                </v-btn>       
            </v-col> 
        </v-card-actions>
    </v-card>
</template>

<script>

    import { mapState, mapMutations, mapActions } from "vuex"

    export default {
        name: "NotasCdcComponent",
        props: {
            partyId: { type: String },
            nombre: { type: String },
            commDevContactId: { type: Number },
            notaName:{
                type: String,
                required: true
            }
        },
        data: () => ({
            notas:[],
            textoNota:null,
            tiposNotas:[],
            overlay:false,
            filtroNotas:'TODOS',
            requiredRule: [(v) => !!v || "El campo es requerido"],
        }),
        computed: {

            ...mapState("master", ["user"]),

            notasFiltradas(){

                if(this.filtroNotas == 'TODOS'){

                    return this.notas

                }else{
                    return this.notas.filter(e => e.note_name == this.filtroNotas)

                }

            }

        },
        methods:{
            
            ...mapMutations("master", ["setUrl"]),
            
            ...mapActions("master", ["requestApi", "alertNotification"]),

            cargarNotas(){

                this.setUrl("notas");
                this.overlay = true

                this.requestApi({
                    method: "GET",
                    data: {
                        typeList: "getNotas",
                        contactId: this.commDevContactId,
                    }
                })
                .then((res) => {
                    
                    console.log(res)
                    this.overlay = false
                    this.notas = res.data._embedded.notas
                    this.tiposNotas = res.data._embedded.notas.map(e => e.note_name).filter((e, i, a) => a.indexOf(e) === i)
                    this.tiposNotas.unshift('TODOS')

                })
              
            },

            storeNota(){

                this.setUrl("notas");
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                        typeAction: "storeNota",
                        noteName: this.notaName,
                        noteInfo: this.textoNota,
                        contactId: this.commDevContactId,
                        partyId: this.partyId,
                        userLoginId: this.user.user_ligin_id,
                    }
                })
                .then((res) => {
                    
                    this.cargarNotas()

                    this.overlay = false
                    
                    this.alertNotification({
                        param:{
                            html: res.data.msg
                        }
                    })
  
                }).catch((err) => {
                    
                    console.log(err)

                    this.overlay = false
                    
                })
                
            },

            updateNota(nota){

                this.setUrl("notas");
                this.overlay = true

                this.requestApi({
                    method: "POST",
                    data: {
                        typeAction: "upadteEstadoNota",
                        noteId: nota.note_id
                    }
                })
                .then((res) => {
                    
                    console.log(res)
                    this.cargarNotas()

                    this.overlay = false
                    
                    this.alertNotification({
                        param:{
                            html: res.data.msg
                        }
                    })
  
                }).catch((err) => {
                    
                    console.log(err)

                    this.overlay = false
                    
                })

            },

            filtarNotas(){


            }

        },
        mounted(){

            this.cargarNotas()

        }

    }

</script>